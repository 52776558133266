import React, { useState, useEffect } from "react";
import logo from "./images/logo.png";
import menuDropDown from "./images/menu-drop-down.svg";
import Home from "./pages/Home";
import Services from "./pages/Services";
import AboutUs from "./pages/AboutUs";
import References from "./pages/References";
import ContactUs from "./pages/ContactUs";
import scrollIntoView from "./helpers/scrollIntoView";
import useMediaQuery from "./helpers/useMediaQuery";
import useDebounce from "./helpers/useDebounce";
import "./App.css";

function App() {
  const isDesktop = useMediaQuery("(min-width: 769px)");
  const [openMenu, setOpenMenu] = useState(false);
  const [showHeaderShadow, setShowHeaderShadow] = useState(false);
  const [sectionsVisibility, setSectionsVisibility] = useState([]);
  const [currentSection, setCurrentSection] = useState("home");
  const debouncedCurrentSection = useDebounce(currentSection, 300);

  useEffect(() => {
    const handleScrollPosition = () => {
      // Add shadow to header if scrollY is greater than zero
      setShowHeaderShadow(!!window.scrollY);
    };
    window.addEventListener("scroll", handleScrollPosition);
    return () => {
      window.removeEventListener("scroll", handleScrollPosition);
    };
  }, []);

  useEffect(() => {
    let newCurrentSection;
    const sections = ["home", "services", "aboutUs", "references", "contactUs"];
    sections.forEach((section) => {
      if (!!sectionsVisibility?.[section]) newCurrentSection = section;
    });
    if (newCurrentSection) setCurrentSection(newCurrentSection);
  }, [sectionsVisibility]);

  const handleSetCurrent = (key, state) => {
    if (sectionsVisibility?.[key] !== state) {
      const newCurrent = { ...sectionsVisibility, [key]: state };
      setSectionsVisibility(newCurrent);
    }
  };

  const handleTopMenuItemClick = (event, id, y) => {
    scrollIntoView(event, id, y || 0);
    if (openMenu) setOpenMenu(false);
  };

  return (
    <>
      <header className={showHeaderShadow ? "header-with-shadow" : undefined}>
        <div>
          <img src={logo} className="logo" alt="Global elearning" loading="lazy" />
          <span className="logo-text">global elearning</span>
          <div>
            {!isDesktop && (
              <div
                id="hamburger"
                className={openMenu ? "hamburger-open" : undefined}
                onClick={() => setOpenMenu(!openMenu)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
            <nav>
              <a
                href="#home"
                rel="noopener noreferrer"
                onClick={(event) => handleTopMenuItemClick(event, "home")}
                className={debouncedCurrentSection === "home" ? "header-nav-a-active" : undefined}
              >
                Home
              </a>
              <a
                href="#services"
                rel="noopener noreferrer"
                onClick={(event) => handleTopMenuItemClick(event, "services", isDesktop ? 0 : 72)}
                className={debouncedCurrentSection === "services" ? "header-nav-a-active" : undefined}
              >
                Services
                <img src={menuDropDown} className="drop-down-icon" alt="More" loading="lazy" />
                <div className="drop-down-menu">
                  <span onClick={(event) => handleTopMenuItemClick(event, "elearning", isDesktop ? 110 : 95)}>
                    E-learning courses
                  </span>
                  <span onClick={(event) => handleTopMenuItemClick(event, "ai-localization", isDesktop ? 110 : 95)}>
                    AI Localization
                  </span>
                  <span onClick={(event) => handleTopMenuItemClick(event, "vo-sub-av", isDesktop ? 110 : 95)}>
                    Voice overs, subtitles & A/V sync
                  </span>
                  <span onClick={(event) => handleTopMenuItemClick(event, "programming", isDesktop ? 110 : 95)}>
                    Programming Support
                  </span>
                </div>
              </a>
              <a
                href="#about-us"
                rel="noopener noreferrer"
                onClick={(event) => handleTopMenuItemClick(event, "about-us", isDesktop ? 0 : 90)}
                className={debouncedCurrentSection === "aboutUs" ? "header-nav-a-active" : undefined}
              >
                About us
              </a>
              <a
                href="#references"
                rel="noopener noreferrer"
                onClick={(event) => handleTopMenuItemClick(event, "references", isDesktop ? 0 : 90)}
                className={debouncedCurrentSection === "references" ? "header-nav-a-active" : undefined}
              >
                References
              </a>
              <a
                href="#contact-us"
                rel="noopener noreferrer"
                onClick={(event) => handleTopMenuItemClick(event, "contact-us", isDesktop ? 0 : 90)}
                className={
                  debouncedCurrentSection === "contactUs" ? "exclusive-link header-nav-a-active" : "exclusive-link"
                }
              >
                Contact us
              </a>
            </nav>
          </div>
        </div>
      </header>

      <main>
        <Home onVisible={handleSetCurrent} />
        <Services onVisible={handleSetCurrent} />
        <AboutUs onVisible={handleSetCurrent} />
        <References onVisible={handleSetCurrent} />
        <ContactUs onVisible={handleSetCurrent} />
      </main>

      <footer>
        <div>
          <address>
            <div>
              <strong>global elearning</strong>
              <br />
              Moravské nám. 754/13
              <br />
              602 00 Brno
              <br />
              Czech Republic
            </div>
            <div>
              <br />
              <a href="tel:+420603574709" rel="noopener noreferrer">
                +420 603 574 709
              </a>
              <br />
              <a href="mailto:hello@global-elearning.com" rel="noopener noreferrer">
                hello@global-elearning.com
              </a>
            </div>
          </address>
          <div>
            <nav>
              <a href="#home" rel="noopener noreferrer" onClick={(event) => scrollIntoView(event, "home")}>
                Home
              </a>
              <a href="#services" rel="noopener noreferrer" onClick={(event) => scrollIntoView(event, "services")}>
                Services
              </a>
              <a href="#about-us" rel="noopener noreferrer" onClick={(event) => scrollIntoView(event, "about-us")}>
                About us
              </a>
              <a href="#references" rel="noopener noreferrer" onClick={(event) => scrollIntoView(event, "references")}>
                References
              </a>
              <a href="#contact-us" rel="noopener noreferrer" onClick={(event) => scrollIntoView(event, "contact-us")}>
                Contact us
              </a>
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
