import React, { useState, useEffect } from "react";
import servicesAiLocalization from "../images/services-ai-localization.svg";
import servicesElearning from "../images/services-elearning.svg";
import chevronIcon from "../images/chevron-right.svg";
import useInViewport from "../helpers/useInViewport";
import "./Services.css";

const Services = ({ onVisible }) => {
  const { ref: rootRef, percentage: rootPercentage } = useInViewport();
  const { ref: elearningRef, visited: elearningVisited } = useInViewport();
  const { ref: moreRef, visited: moreVisited } = useInViewport();
  const [accordionStates, setAccordionStates] = useState({ a1: true });

  useEffect(() => {
    onVisible("services", rootPercentage >= 0.5);
  }, [rootPercentage, onVisible]);

  const toggleAccordion = (event, id) => {
    event.preventDefault();
    const newState = !accordionStates?.[id];
    setAccordionStates({ ...accordionStates, [id]: newState });
  };

  return (
    <section id="services" ref={rootRef}>
      <article ref={elearningRef} className={elearningVisited ? "show show-in" : "show"}>
        <div>
          <h1>Our services</h1>
          <h2 id="elearning" style={{ scrollPaddingTop: elearningVisited ? 0 : 100 }}>
            E-learning courses
          </h2>
          <p>
            Let us transform your knowledge into a&nbsp;digital world with a global reach. Any&nbsp;course or workflow
            you choose, we&nbsp;design with localization in mind. Simplicity and straightforwardness included.
          </p>
          <div className={accordionStates?.["a1"] ? "accordion" : "accordion accordion-closed"}>
            <div className="accordion-label" onClick={(event) => toggleAccordion(event, "a1")}>
              <img src={chevronIcon} alt=">" />
              Instructional Design
            </div>
            <div className="accordion-content">
              <p className="dimmed">Choose solid kick-ass courses over boring powerpoints</p>
              <p>
                We redesign your presentations and create an interactive course using instructional design principles.
                From now on, all eyes on your course.
              </p>
              <p>
                First, let’s identify your learning objectives. Our instructional designers love this part! Then collect
                your training materials and perfectionalize them by double-checking with your experts on
                the&nbsp;subject. If a content is the king, then a structure is the queen. We&nbsp;organize selected
                content, create a storyboard, and deliver HQ courses with the impact that lasts.
              </p>
            </div>
          </div>
          <div className={accordionStates?.["a2"] ? "accordion" : "accordion accordion-closed"}>
            <div className="accordion-label" onClick={(event) => toggleAccordion(event, "a2")}>
              <img src={chevronIcon} alt=">" />
              Custom e-learning
            </div>
            <div className="accordion-content">
              <p className="dimmed">Watch the content become your own masterpiece from the scratch</p>
              <p>
                Just like wearing a perfectly fitting coat, <strong>tailored content</strong> must fit your brand, speak
                your language and satisfy your target group.
              </p>
              <p>
                Old templates are… well, they are old news, really. Let’s rather create something new. Together. Remove
                barriers to learning by getting a modern training solution. <strong>Custom e-learning</strong> increases
                engagement, follows exactly the spirit of your company, and it’s accessible and personalized as much as
                you need it to be.
              </p>
              <p>
                Reach your business goals by closing the knowledge gaps between you and your learners. We utilize the
                right tools and blend of engaging training methods so that you can level up your training content.
              </p>
            </div>
          </div>
          <div className={accordionStates?.["a3"] ? "accordion" : "accordion accordion-closed"}>
            <div className="accordion-label" onClick={(event) => toggleAccordion(event, "a3")}>
              <img src={chevronIcon} alt=">" />
              From Storyboard to your LMS
            </div>
            <div className="accordion-content">
              <p>
                So you have your internal instructional design team but you <strong>lack engineers</strong> to transform
                your ideas from paper to digital? Do&nbsp;you already have a concept, a&nbsp;design, and
                a&nbsp;storyboard of your course in place and all you need is{" "}
                <strong>a team that would take it from here</strong>?
              </p>
              <p>
                We will happily deploy a digital prototype, beta release, help you with all the reviews and testing so
                that your precious course is soon published to your preferred LMS.
              </p>
              <p>
                We have a large team of Articulate, Storyline, Captivate, and Lectora professionals ready at your
                disposal.
              </p>
            </div>
          </div>
        </div>
        <div>
          <img src={servicesElearning} alt="E-learning services" width="444" height="452" />
        </div>
      </article>
      <br />
      <br />
      <article ref={moreRef} className={moreVisited ? "show show-in" : "show"}>
        <div>
          <h2 id="ai-localization" style={{ scrollPaddingTop: moreVisited ? 0 : 100 }}>
            AI Localization
          </h2>
          <p>
            We localize your content to any language desired using a combination of traditional translators and
            AI-driven technology for better efficiency and price. Since we have been in localization for almost 20 years
            we know how to save unnecessary costs. When it comes to e-learning, the most crucial part of the whole
            process is the localization-ready source e-learning piece. We&nbsp;support most of the common Western,
            Middle Eastern, and Asian languages.
          </p>
          <br />
          <br />
          <h2 id="vo-sub-av" style={{ scrollPaddingTop: moreVisited ? 0 : 100 }}>
            Voice overs, subtitles & A/V sync
          </h2>
          <p>
            Every course needs a perfect narration or at least quality subtitles. We&nbsp;cover both. Our&nbsp;voice
            talents narrate for us in more than 50&nbsp;languages. We&nbsp;do our best to match your needs with the
            talents so that your courses are natural and fun to learn from. Our in-house team had already synched
            thousands of hours of voice overs and created countless subtitles in various languages.
          </p>
          <br />
          <br />
          <h2 id="programming" style={{ scrollPaddingTop: moreVisited ? 0 : 100 }}>
            Programming Support
          </h2>
          <p>
            We also support our clients with a basic programming for HTML5, CSS3, JavaScript, JSON, XML and ActionScript
            3.
          </p>
        </div>
        <div>
          <img src={servicesAiLocalization} alt="AI Localization" />
        </div>
      </article>
    </section>
  );
};

export default Services;
