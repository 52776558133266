import React, { useState, useEffect } from "react";
import aboutUsIllustration from "../images/about-us-illustration.svg";
import lmsLogos from "../images/lms-logos.svg";
import toolsCaptivate from "../images/tools-captivate.svg";
import toolsArticulate from "../images/tools-articulate.png";
import toolsLectora from "../images/tools-lectora.svg";
import toolsIspring from "../images/tools-ispring.svg";
import toolsCamtasiaStudio from "../images/tools-camtasia.svg";
import toolsAfterEffects from "../images/tools-ae.svg";
import toolsPremiere from "../images/tools-pr.svg";
import toolsPhotoshop from "../images/tools-ps.svg";
import toolsIllustrator from "../images/tools-ai.svg";
import toolsMediaEncoder from "../images/tools-me.svg";
import toolsFinalCut from "../images/tools-fc.png";
import samplesArrows from "../images/samples-arrows.svg";
import clientBentley from "../images/client-bentley.svg";
import clientMcdonald from "../images/client-mcdonald.svg";
import client3m from "../images/client-3m.svg";
import clientDell from "../images/client-dell.svg";
import clientDematic from "../images/client-dematic.svg";
import clientDigitallearn from "../images/client-digitallearn.png";
import clientEdriving from "../images/client-edriving.png";
import clientIbm from "../images/client-ibm.svg";
import clientKb4 from "../images/client-kb4.png";
import clientLobsterink from "../images/client-lobsterink.svg";
import clientMedtronic from "../images/client-medtronic.svg";
import clientMetlife from "../images/client-metlife.svg";
import clientMicrosoft from "../images/client-microsoft.svg";
import clientRoche from "../images/client-roche.svg";
import clientSaintgobain from "../images/client-saintgobain.svg";
import clientSanofi from "../images/client-sanofi.svg";
import clientSeiu from "../images/client-seiu.svg";
import useInViewport from "../helpers/useInViewport";
import sendEmail from "../helpers/sendEmail";
import "./AboutUs.css";

const AboutUs = ({ onVisible }) => {
  const [email, setEmail] = useState("");
  const { ref: rootRef, percentage: rootPercentage } = useInViewport();
  const { ref: storyRef, visited: storyVisited } = useInViewport();
  const { ref: toolsRef, visited: toolsVisited } = useInViewport();
  const { ref: samplesRef, visited: samplesVisited } = useInViewport();
  const { ref: clientsRef, visited: clientsVisited } = useInViewport();

  useEffect(() => {
    onVisible("aboutUs", rootPercentage >= 0.5);
  }, [rootPercentage, onVisible]);

  const handleRequestSamples = (event) => {
    event.preventDefault();
    sendEmail(
      { name: email, email, subject: "Request for samples", message: "Please send me the sameples." },
      (result) => {
        if (result.status) setEmail(false);
      }
    );
  };

  return (
    <section id="about-us" ref={rootRef}>
      <article ref={storyRef} className={storyVisited ? "show show-in" : "show"}>
        <div>
          <h1>About us</h1>
          <h3>Our story</h3>
          <p>
            We started as a small team almost 20&nbsp;years ago. It&nbsp;was a time when the localization business was
            still new. Slowly but steadily, we became one of the market’s leading multimedia companies, serving the
            largest localization service providers worldwide. Having worked on hundreds of e-learning courses, we
            developed unique expertise and best practices for the effective creation of e-learning courses. Especially
            when it comes to more than just one language.
          </p>
        </div>
        <div>
          <img src={aboutUsIllustration} alt="About us" />
        </div>
      </article>
      <br />
      <br />
      <br />
      <article ref={toolsRef} className={toolsVisited ? "show show-in" : "show"}>
        <div>
          <h3>LMS Partnership</h3>
          <p>
            We are proud to work with Moodle, Docebo and TalentLMS.
            <br />
            <br />
            <img src={lmsLogos} alt="LMS logos" style={{ paddingRight: 30, boxSizing: "border-box" }} />
          </p>
        </div>
        <div className="tools-and-formats">
          <h3>Tools & formats we work with</h3>
          <div className="about-us-tools">
            <img src={toolsCaptivate} alt="Adobe Captivate" loading="lazy" />
            <img src={toolsArticulate} alt="Articulate" loading="lazy" />
            <img src={toolsLectora} alt="Lectora" loading="lazy" />
            <img src={toolsIspring} alt="iSpring" loading="lazy" />
          </div>
          <div className="about-us-tools">
            <img src={toolsCamtasiaStudio} alt="Techmith's Camtasia Studio" loading="lazy" />
            <img src={toolsAfterEffects} alt="Adobe AfterEffects" loading="lazy" />
            <img src={toolsPremiere} alt="Adobe Premiere Pro" loading="lazy" />
            <img src={toolsPhotoshop} alt="Adobe Photoshop" loading="lazy" />
            <img src={toolsIllustrator} alt="Adobe Illustrator" loading="lazy" />
            <img src={toolsMediaEncoder} alt="Adobe Media Encoder" loading="lazy" />
            <img src={toolsFinalCut} alt="Final Cut" loading="lazy" />
          </div>
          <p>SCORM 1.2, SCORM 2004, AICC, HTML5 or TinCan API.</p>
        </div>
      </article>
      <article ref={samplesRef} className={samplesVisited ? "show show-in" : "show"}>
        <div>
          <div className="samples">
            <div>
              <h3>Samples</h3>
              <p>
                <span>We can send you the completed project samples upon request. </span>
                <span>Leave us your email and we will get back to you.</span>
              </p>
            </div>
            <div className="samples-request">
              <input
                type="email"
                inputMode="email"
                placeholder="your-email@domain.com"
                value={email || ""}
                onChange={(event) => setEmail(event.target.value)}
                disabled={email === false}
              />
              <button type="button" name="request-samples" disabled={!email} onClick={handleRequestSamples}>
                {email === false ? "Request sent!" : "Request samples"}
              </button>
              <img src={samplesArrows} className="samples-arrows" alt="Apply here" loading="lazy" />
            </div>
          </div>
        </div>
      </article>
      <article ref={clientsRef} className={clientsVisited ? "show show-in" : "show"}>
        <div>
          <h3>Our clients</h3>
          <div className="clients">
            <span>
              <img src={clientMcdonald} alt="MC Donald" loading="lazy" />
            </span>
            <span>
              <img src={clientBentley} alt="Bentley" loading="lazy" />
            </span>
            <span>
              <img src={clientRoche} alt="Roche" loading="lazy" />
            </span>
            <span>
              <img src={clientSeiu} alt="SEIU" loading="lazy" />
            </span>
            <span>
              <img src={clientEdriving} alt="e-driving" loading="lazy" />
            </span>
            <span>
              <img src={clientMedtronic} alt="Medtronic" loading="lazy" />
            </span>
            <span>
              <img src={clientKb4} alt="KnowBe4" loading="lazy" />
            </span>
            <span>
              <img src={clientDigitallearn} alt="Digital Learn" loading="lazy" />
            </span>
            <span>
              <img src={clientLobsterink} alt="Lobster Ink" loading="lazy" />
            </span>
            <span>
              <img src={clientDematic} alt="Dematic" loading="lazy" />
            </span>
            <span>
              <img src={clientSaintgobain} alt="Saint-gobain" loading="lazy" />
            </span>
            <span>
              <img src={clientSanofi} alt="Sanofi" loading="lazy" />
            </span>
            <span>
              <img src={client3m} alt="3M" loading="lazy" />
            </span>
            <span>
              <img src={clientMetlife} alt="Metlife" loading="lazy" />
            </span>
            <span>
              <img src={clientIbm} alt="IBM" loading="lazy" />
            </span>
            <span>
              <img src={clientMicrosoft} alt="Microsoft" loading="lazy" />
            </span>
            <span>
              <img src={clientDell} alt="DELL" loading="lazy" />
            </span>
          </div>
        </div>
      </article>
    </section>
  );
};

export default AboutUs;
