import { useState, useEffect } from "react";
import PropTypes from "prop-types";

function AnimatedNumber({ startNumber, endNumber, duration }) {
  const [resultNumber, setResultNumber] = useState(0);

  useEffect(() => {
    let timer;
    // calculate range
    const range = endNumber - startNumber;
    // no timer shorter than 50ms (not really visible any way)
    const minTimer = 50;
    // calc step time to show all interediate values
    let stepTime = Math.abs(Math.floor(duration / range));
    // never go below minTimer
    stepTime = Math.max(stepTime, minTimer);
    // get current time and calculate desired end time
    const startTime = new Date().getTime();
    const endTime = startTime + duration;

    const run = () => {
      var now = new Date().getTime();
      var remaining = Math.max((endTime - now) / duration, 0);
      var value = Math.round(endNumber - remaining * range);
      setResultNumber(!isNaN(value) ? value : 0);
      if (value === endNumber) clearInterval(timer);
    };

    timer = setInterval(run, stepTime);
    run();

    return () => clearInterval(timer);
  }, [startNumber, endNumber, duration]);

  const formatNumber = (num) => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");

  return formatNumber(resultNumber);
}

AnimatedNumber.propTypes = {
  startNumber: PropTypes.number,
  endNumber: PropTypes.number,
  duration: PropTypes.number,
};

AnimatedNumber.defaultProps = {
  startNumber: 0,
  endNumber: 0,
  duration: 2000,
};

export default AnimatedNumber;
