function scrollIntoView(event, elementId, offset = 0) {
  event.preventDefault();
  event.stopPropagation();

  const element = document.getElementById(elementId);
  const rect = element.getBoundingClientRect();

  //element?.scrollIntoView({ behavior: "smooth" });

  //const elementPosition = element.offsetTop;
  //const offsetPosition = elementPosition - offset;
  //console.log(offsetPosition, element.getBoundingClientRect());
  //window.scrollTo({ top: offsetPosition, behavior: "smooth" });

  const scrollPaddingTop = parseFloat(element.style.scrollPaddingTop) || 0;

  //console.log(element.offsetTop, window.scrollY + rect.top, scrollPaddingTop);

  window.scrollTo({ top: window.scrollY + rect.top - offset - scrollPaddingTop, behavior: "smooth" });
}

export default scrollIntoView;
