import React, { useState, useEffect } from "react";
import contactUsIllustration from "../images/contact-us-illustration.svg";
import useInViewport from "../helpers/useInViewport";
import sendEmail from "../helpers/sendEmail";
import "./ContactUs.css";

const ContactUs = ({ onVisible }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { ref, percentage, visited } = useInViewport();

  useEffect(() => {
    onVisible("contactUs", percentage >= 0.75);
  }, [percentage, onVisible]);

  const submitForm = (event) => {
    event.preventDefault();
    sendEmail({ name, email, subject, message }, (result) => {
      if (result.status) {
        setName("");
        setEmail("");
        setSubject("");
        setMessage(false);
      } else {
        console.log(result);
      }
    });
  };

  return (
    <section id="contact-us">
      <article ref={ref} className={visited ? "show show-in" : "show"}>
        <div>
          <h1>Ask for a quote</h1>
          <p>
            If you don't like forms, drop us an email at{" "}
            <a href="mailto:quote@global-elearning.com" rel="noopener noreferrer">
              quote@global-elearning.com
            </a>
          </p>
          <div className="contact-form">
            <div>
              <label htmlFor="contact-name">Your name</label>
              <input
                id="contact-name"
                name="name"
                inputMode="text"
                placeholder="Enter your name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="contact-email">Your email</label>
              <input
                id="contact-email"
                name="email"
                type="email"
                inputMode="email"
                placeholder="example@domain.com"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="contact-subject">Subject</label>
              <input
                id="contact-subject"
                name="subject"
                inputMode="text"
                placeholder="Enter subject of your message"
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="contact-message">Message</label>
              <textarea
                id="contact-message"
                name="message"
                placeholder="Write down the message"
                value={message || ""}
                onChange={(event) => setMessage(event.target.value)}
                required
              />
            </div>
            <div>
              <button
                type="button"
                name="send-message"
                disabled={!name || !email || !subject || !message}
                onClick={submitForm}
              >
                {message === false ? "Message sent!" : "Send the message"}
              </button>
            </div>
          </div>
        </div>
        <div>
          <img src={contactUsIllustration} className="contact-us-illustration" alt="Contact us" />
        </div>
      </article>
    </section>
  );
};

export default ContactUs;
