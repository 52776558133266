import { useState, useEffect, useRef } from "react";

function useInViewport(props) {
  const { log, minPxVisited } = { log: () => {}, minPxVisited: 1, ...(props ? props : {}) };
  const ref = useRef(null);
  const [percentage, setPercentage] = useState(0);
  const [px, setPx] = useState(0);
  const [top, setTop] = useState(0);
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    // Listener function
    const calculate = () => {
      var rect = ref.current?.getBoundingClientRect();
      if (rect) {
        //console.log(rect);
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const top = Math.max(rect.height + rect.top, 0) / rect.height;
        const bottom = Math.max(windowHeight - rect.top, 0) / rect.height;
        const percent = rect.top < 0 ? top : rect.top + rect.height > windowHeight ? bottom : 1;
        const visiblePercentage = Math.min(Math.max(percent, 0), 1);
        setPercentage(visiblePercentage);
        // Calculate visible pixels
        const visiblePx = Math.min(Math.max(windowHeight - rect.top, 0), rect.height);
        setPx(visiblePx);
        // Update visited if needed
        if (visiblePx >= minPxVisited && !visited) setVisited(true);
        // Update top value
        setTop(rect.top);
      }
    };
    // Register scroll event listener
    window.addEventListener("scroll", calculate, false);
    // Initialize
    calculate();

    return () => {
      // Unregister scroll event listener
      window.removeEventListener("scroll", calculate);
    };
  }, [ref, minPxVisited, visited]);

  // Logging
  useEffect(() => {
    if (typeof log === "function") {
      log({ percentage, px, visited, top });
    }
  }, [percentage, px, visited, log, top]);

  return { ref, percentage, px, visited, top };
}

export default useInViewport;
