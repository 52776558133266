function sendEmail(params, returnFn) {
  // Construct form data
  var formData = new FormData();
  // Fill form data
  for (const [key, value] of Object.entries(params)) formData.append(key, value);
  // Construct XMLHttpRequest
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => {
    if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
      var result = JSON.parse(xmlHttp.responseText);
      returnFn(result);
      // Show errors
      //if (String(typeof result.error) !== "undefined") console.log(result.error, result.err_fields);
      // Show result
      //if (result.status) console.log("Email sent.");
    }
  };
  xmlHttp.open("post", "/static/php/mail.php");
  xmlHttp.send(formData);
}

export default sendEmail;
