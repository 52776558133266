import React from "react";
//import refDn from "../images/ref-dn.png";
//import refDp from "../images/ref-dp.png";
//import refMa from "../images/ref-ma.png";
import heartOne from "../images/heart-one.svg";
import heartTwo from "../images/heart-two.svg";
import heartThree from "../images/heart-three.svg";
import useInViewport from "../helpers/useInViewport";
import "./References.css";

const References = ({ onVisible }) => {
  const { ref: rootRef, percentage: rootPercentage } = useInViewport();
  const { ref: cardsRef, visited: cardsVisited } = useInViewport();
  const { ref: noteRef, visited: noteVisited } = useInViewport();

  React.useEffect(() => {
    onVisible("references", rootPercentage >= 0.5);
  }, [rootPercentage, onVisible]);

  return (
    <section id="references" ref={rootRef}>
      <article ref={cardsRef} className={cardsVisited ? "show show-in" : "show"}>
        <div>
          <h3>References</h3>
          <div className="reference-cards">
            <div>
              <p className="reference-title">Efficiency and commitment to quality</p>
              <p className="reference-quote">
                “global elearning has been helping with our e-learning and video projects for the last few years. I am
                quite impressed by their reliability, efficiency and commitment to quality.”
              </p>
              <div className="reference-contact">
                {/*<img src={refDp} alt="David Peterson" loading="lazy" />
                <div>
                  <strong>David Peterson</strong>
                  <br />
                  Microsoft.com
  </div>*/}
                <div>—</div>
                <div>
                  Head of Localization, <br />
                  US Telecommunications Company
                </div>
              </div>
            </div>
            <div>
              <p className="reference-title">Expertise and knowledge in Captivate and Storyline</p>
              <p className="reference-quote">
                “I really love these guys. Their expertise and knowledge, especially in Adobe Captivate and Articulate
                Storyline is stunning. We have never worked with a better solution-oriented team.”
              </p>
              <div className="reference-contact">
                {/*<img src={refMa} alt="Michelle Alba" loading="lazy" />
                <div>
                  <strong>Michelle Alba</strong>
                  <br />
                  RWS
                </div>*/}
                <div>—</div>
                <div>
                  Project Manager, <br />
                  Life Sciences Company
                </div>
              </div>
              <img src={heartOne} className="reference-heart" alt="❤" loading="lazy" />
              <img src={heartTwo} className="reference-heart" alt="❤" loading="lazy" />
              <img src={heartThree} className="reference-heart" alt="❤" loading="lazy" />
            </div>
            <div>
              <p className="reference-title">Approach to instructional design</p>
              <p className="reference-quote">
                “We really like their approach to instructional design. Their solution is very straightforward. I would
                recommend them 100%.”
              </p>
              <div className="reference-contact">
                {/*<img src={refDn} alt="David Novák" loading="lazy" />
                <div>
                  <strong>David Novák</strong>
                  <br />
                  RWS
                </div>*/}
                <div>—</div>
                <div>
                  L&D Manager, <br />
                  EU Engineering Company
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article ref={noteRef} className={noteVisited ? "show show-in" : "show"}>
        <p className="wide-note">
          There are a lot of freelancers & companies who can help you design your courses, but very few have real
          experience with localization. <br />
          <strong>We do. Ask for a quote.</strong>
        </p>
      </article>
    </section>
  );
};

export default References;
