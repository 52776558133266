import React, { useEffect } from "react";
import introIllustration from "../images/intro-illustration.svg";
import introTools from "../images/intro-tools.png";
import AnimatedNumber from "../helpers/AnimatedNumber";
import scrollIntoView from "../helpers/scrollIntoView";
import useMediaQuery from "../helpers/useMediaQuery";
import useInViewport from "../helpers/useInViewport";
import "./Home.css";

const Home = ({ onVisible }) => {
  const { ref: rootRef, percentage: rootPercentage } = useInViewport();
  const { ref: weRef, visited: weVisited } = useInViewport();
  const { ref: numbersRef, visited: numbersVisited } = useInViewport();
  const isDesktop = useMediaQuery("(min-width: 769px)");

  useEffect(() => {
    onVisible("home", rootPercentage >= 0.5);
  }, [rootPercentage, onVisible]);

  return (
    <section id="home" ref={rootRef}>
      <article ref={weRef} className={weVisited ? "show show-in" : "show"}>
        <div>
          <h1 className="intro-title">
            We design and localize <span className="no-break">e-learning</span> courses in{" "}
            <span className="no-break">120+ languages</span>
            <span style={{ display: "none" }}>
              e-learning, elearning, blended learning, virtual learning, online training, microlearning, mlearning,
              digital learning, online training programs, e-learning companies, elearning company, voiceovers, elearning
              developer, elearning solutions, elearning providers, e-learning translation, elearning content
              development, elearning course design, elearning course development, elearning translations, elearning
              content providers, elearning solution providers, elearning solutions company, rapid elearning development,
              elearning developer portfolio, elearning service provider, virtual learning professional development for
              teachers, compliance elearning providers, custom elearning content, custom elearning content development,
              elearning course providers, personalized e-learning, personalized elearning, rapid elearning development
              process, rapid elearning solutions, online training content developer, online training program developer
            </span>
          </h1>
          <p className="intro-subtitle">
            We are localisation veterans, engineers, project managers, translators, instructional designers, graphic
            designers, video/audio experts using AI&nbsp;algorithms.
          </p>
          <img src={introTools} className="intro-tools" alt="Tools" loading="lazy" width="253" height="29" />
          <a
            className="button-link"
            href="#services"
            rel="noopener noreferrer"
            onClick={(event) => scrollIntoView(event, "services", isDesktop ? undefined : 72)}
          >
            Find out more
          </a>
        </div>
        <div>
          <img src={introIllustration} alt="Global elearning" loading="lazy" width="610" height="558" />
        </div>
      </article>
      <article ref={numbersRef} className={numbersVisited ? "numbers show show-in" : "numbers show"}>
        <div className={numbersVisited ? "visible-numbers" : undefined}>
          <p>
            <AnimatedNumber endNumber={20} />
          </p>
          <p>Years in business</p>
        </div>
        <div className={numbersVisited ? "visible-numbers" : undefined}>
          <p>
            <AnimatedNumber endNumber={845} duration={2500} />
          </p>
          <p>Courses published</p>
        </div>
        <div className={numbersVisited ? "visible-numbers" : undefined}>
          <p>
            <AnimatedNumber endNumber={958000} duration={3000} />
          </p>
          <p>Slides processed</p>
        </div>
        <div className={numbersVisited ? "visible-numbers" : undefined}>
          <p>
            <AnimatedNumber endNumber={54} duration={3500} />
          </p>
          <p>Languages adopted</p>
        </div>
        <div className={numbersVisited ? "visible-numbers" : undefined}>
          <p>
            <AnimatedNumber endNumber={50} duration={4000} />+
          </p>
          <p>Team members</p>
        </div>
      </article>
    </section>
  );
};

export default Home;
